

const Footer = () => {
  return (
    <div className=" r-flex align-items-center justify-content-center text-white bg-dark" style={{ fontSize:"12px" }}>
        <div className=" d-flex align-items-center justify-content-center ">
        <img
              alt=""
              src="https://uploadstatic-sea.mihoyo.com/hk4e/upload/officialsites/202004/GIEN_1587984203_6764.png"
              width="133"
              height="60"
              className="r-flex justify-content-center  d-inline-block align-top "
            />{' '}

        </div>
        <div className=" d-flex align-items-center justify-content-center ">
            
        <ul class="d-flex flex-wrap">
  <li class="list-group-item">Cras justo odio</li>
  <li class="list-group-item">Dapibus ac facilisis in</li>
  <li class="list-group-item">Morbi leo risus</li>

</ul>

        </div>
        <div className=" d-flex align-items-center justify-content-center "> 
        <img
              alt=""
              src="https://webstatic-sea.hoyoverse.com/upload/op-public/2023/02/02/6d41c6960e73a2f264132bdbbff60ce3_3994663197512018697.png"
              width="151"
              height="96"
              className="r-flex justify-content-center  d-inline-block align-top "
            />{' '}
        </div>
        <p className=" d-flex align-items-center justify-content-center "> "PlayStation Family Mark," "PS5 logo" and "PS4 logo" are registered trademarks or trademarks of Sony Interactive Entertainment Inc. <br />
        Epic, Epic Games, Epic Games Store, the Epic Games Store logo, and Epic Online Services are trademarks and/or registered trademarks of Epic Games. All other trademarks are the property of their respective owners.</p>

        <div className=" d-flex align-items-center justify-content-center "> 
        <img
              alt=""
              src="https://webstatic.hoyoverse.com/upload/event/2021/12/29/eaa64d81ae6a7318a6afb91cbffd83a4_118924438105936743.png"
              width="316"
              height="57"
              className="r-flex justify-content-center  d-inline-block align-top "
            />{' '}
        </div>
        <div className=" d-flex align-items-center justify-content-center ">Copyright © COGNOSPHERE. All Rights Reserved.</div>


    </div>
  )
}

export default Footer