import React from 'react-bootstrap'
import Container from "react-bootstrap/Container";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useState } from 'react';
import Table from 'react-bootstrap/Table';
import Image from "react-bootstrap/Image";
import UserProfile from '../UserProfile';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Body.css'

const Body = () => {


    const [isChecked, setIsChecked] = useState(false);

  return (
    

    <Container className=''>
        <h1 className='text-center'>Twitch主播邀约奖励计划</h1>
        <h2 className='text-center fw-light mt-4 pb-3'>参与活动一起瓜分 100,000 USD大奖</h2>

          <div className='r-flex justify-content-center pt-4 '>
             <div className='d-flex justify-content-center '> 
             <Button variant="outline-secondary" size="lg" >点击参与</Button>{' '}
             </div>  

              <div className="form-check d-flex justify-content-center mt-2 gap-2 pb-5">
                  <input className="form-check-input"  type="checkbox" value="" id="flexCheckDefault" />
                  <label className="form-check-label" for="flexCheckDefault">
                      确认了解相关活动规则
                  </label>
              </div>

          </div>


        <div className='r-flex justify-content-center mt-5 pt-5'> 
        <h3 className='text-center mb-4'>活动guideline</h3>
        <div className='d-flex justify-content-center '>
        <Card style={{ width: '100%',height:'20rem', overflowY: 'scroll' }}>   
      <Card.Body >
        <Card.Title>推荐直播内容</Card.Title>
        
        <Card.Text>
        新冠疫情仍在全球蔓延。图为3月12日，中国与世界卫生组织在北京以视频连线方式举办新冠肺炎防治中国经验国
                  际通报会。以色列内盖夫本-古里安大学公共卫生系主任纳达夫·戴维诺维奇也表示，针对大流行病起诉一个国家是
                  不会成功的，也不现实。新冠疫情仍在全球蔓延。
                  图为3月12日，中国与世界卫生组织在北京
        </Card.Text>
        <Card.Title>活动规则</Card.Title>
        
        <Card.Text>
        新冠疫情仍在全球蔓延。图为3月12日，中国与世界卫生组织在北京以视频连线方式举办新冠肺炎防治中国经验国
                  际通报会。以色列内盖夫本-古里安大学公共卫生系主任纳达夫·戴维诺维奇也表示，针对大流行病起诉一个国家是
                  不会成功的，也不现实。新冠疫情仍在全球蔓延。
                  图为3月12日，中国与世界卫生组织在北京色列内盖夫本-古里安大学公共卫生系主任纳达夫·戴维诺维奇也表示，针对大流行病起诉一个国家是
                  不会成功的，也不现实。新冠疫情仍在全球蔓延。
                  图为3月12日，中国与世界卫生组织在北京
                  色列内盖夫本-古里安大学公共卫生系主任纳达夫·戴维诺维奇也表示，针对大流行病起诉一个国家是
                  不会成功的，也不现实。新冠疫情仍在全球蔓延。
                  图为3月12日，中国与世界卫生组织在北京
        </Card.Text>

        {/* <Card.Link href="#">Card Link</Card.Link>
        <Card.Link href="#">Another Link</Card.Link> */}
      </Card.Body>
    </Card>

        </div>
        
        </div>

         


        <div className=" r-flex align-items-center justify-content-center mt-5 pt-5" >
        
        <h3 className=" d-flex align-items-center justify-content-center ">头部邀约计划排行榜</h3>
        <h4 className=" d-flex align-items-center justify-content-center ">一个副标题</h4>


        <Row className=' border border-dark text-center  mt-4 square'>
        <Col>
        <h4 className='my-4'>队伍展示</h4>
        < UserProfile />
        </Col>

        <Col md={8} >
        <h3 className='my-3'>团队总得分：111,11,233</h3>
       <h3 className='mb-4'>能获得 3,000USD</h3>
       <Table  striped bordered hover id='tb_Rank'>
      <thead>
        <tr>
          <th>队伍排名</th>
          <th>队长名称</th>
          <th>团队热力值</th>
          
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>Mark</td>
          <td>13,886</td>
         
        </tr>
        <tr>
          <td>2</td>
          <td>Jacob</td>
          <td>11,454</td>
          
        </tr>
        <tr>
          <td>3</td>
          <td>Marck</td>
          <td>4,223</td>
          
        </tr>
        <tr>
          <td>4</td>
          <td>Jszacob</td>
          <td>234</td>
          
        </tr>
      </tbody>
    </Table>
        </Col>
       
      </Row>

       

        </div>







        <div className=" r-flex align-items-center justify-content-center mt-5 pt-5" >
        <h3 className=' d-flex align-items-center justify-content-center'>个人直播榜</h3>
              <h4 className=' d-flex align-items-center justify-content-center'>一个副标题</h4>

        <div className=" r-flex align-items-center justify-content-center square border border-dark mt-4" style={{  }}  >

            <div className='d-flex mt-3  ' style={{ display: "flex", width: "60%", margin: "0 auto",
  "justify-content": "space-between",
  "align-items": "center"}}>
            <Button variant="outline-secondary "   size="lg" >点击参与</Button>{' '}
            <Button variant="outline-secondary" size="lg" >点击参与</Button>{' '}
            <Button variant="outline-secondary" size="lg" >点击参与</Button>{' '}
            </div>
            <div>
            <Table striped bordered hover id='tb_User'  className='mt-3'>
                          <thead>
                              <tr>
                                  <th>排名</th>
                                  <th>主播</th>
                                  <th>热力值</th>

                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td>1</td>
                                  <td>Mark</td>
                                  <td>13,886</td>

                              </tr>
                              <tr>
                                  <td>2</td>
                                  <td>Jacob</td>
                                  <td>11,454</td>

                              </tr>
                              <tr>
                                  <td>3</td>
                                  <td>Marck</td>
                                  <td>4,223</td>

                              </tr>
                              <tr>
                                  <td>4</td>
                                  <td>Jszacob</td>
                                  <td>234</td>

                              </tr>
                          </tbody>
                      </Table>


                      <Row className='bg-info text-white my-3 text-center 'style={{ width:"60%", margin: "0 auto" , display: "flex", alignItems: "center"}} >
                      <Col md={3} >我的排名：#1</Col>
                      <Col md={3}>热力值：1.1243</Col>
                      
                      <Col md={6} ><Image
              alt=""
              src="https://64.media.tumblr.com/1f1128ea37c1933d64b92cf8109589b7/87fcfc4668007220-1d/s400x600/7cd05bc0da180e0219a279afd44b2771e75ea4d8.png" roundedCircle
              width="48"
              height="48" 
              
              
           />{'未上榜 '}</Col>
                      </Row>
                      
                     
                      

               

            </div>

       

        </div>
        </div>







          {/* square border border-dark */}


          <div className='r-flex justify-content-center mt-5 position-relative '>
              <h3 className='text-center my-4 py-3'>原神头号主播招募</h3>
              <div className='d-flex justify-content-center '>
                  <Card className='border-dark  ' style={{ width: '100%', height: '20rem', overflowY: 'scroll' }}>
                      <Card.Body >
                          {/* <Card.Title>推荐直播内容</Card.Title> */}



                          <Card.Text className='mt-4 mb-5'>
                              新冠疫情仍在全球蔓延。图为3月12日，中国与世界卫生组织在北京以视频连线方式举办新冠肺炎防治中国经验国
                              际通报会。以色列内盖夫本-古里安大学公共卫生系主任纳达夫·戴维诺维奇也表示，针对大流行病起诉一个国家是
                              不会成功的，也不现实。新冠疫情仍在全球蔓延。

                              图为3月12日，中国与世界卫生组织在北京以视频连线方式举办新冠肺炎防治中国经验国际通报会。以色列内盖夫
                              本-古里安大学公共卫生系主任纳达夫·戴维诺维奇也表示，针对大流行病起诉一个国家是不会成功的，也不现实。
                              新冠疫情仍在全球蔓延。图为3月12日，中国与世界卫生组织在北京以视频连线方式举办新冠肺炎防治中国经验国
                              际通报会。以色列内盖夫本-古里安大学公共卫生系主任纳达夫·戴维诺维奇也表示，针对大流行病起诉一个国家是
                              不会成功的，也不现实。

                              新冠疫情仍在全球蔓延。图为3月12日，中国与世界卫生组织在北京以视频连线方式举办新冠肺炎防治中国经验国
                              际通报会。以色列内盖夫本-古里安大学公共卫生系主任纳达夫·戴维诺维奇也表示，针对大流行病起诉一个国家是
                              不会成功的，也不现实。
                          </Card.Text>

                          {/* <Card.Link href="#">Card Link</Card.Link>
        <Card.Link href="#">Another Link</Card.Link> */}

                      </Card.Body>

                  </Card>

              </div>
              <div className='d-flex justify-content-center position-absolute  bottom-0 start-50 translate-middle '> <Button variant="outline-secondary" className='' >点击参与</Button>{' '}</div>

          </div>








      </Container>
  )
}

export default Body