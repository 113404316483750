import logo from './logo.svg';
import './App.css';
import {Header,Footer,Body,Investigation} from './components'
function App() {
  return (
    <div className="App">
      <Header />
      <div className='container m-auto'  >
      <Body />
      </div>
      
      <Investigation />
      <Footer />

    </div>
  );
}

export default App;
