import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

const Header = () => {
  return (


  
<Navbar bg="white" variant="light"  >
        <Container>
          <Navbar.Brand href="https://genshin.hoyoverse.com/">
            <img
              alt=""
              src="https://uploadstatic-sea.mihoyo.com/hk4e/upload/officialsites/202004/GIEN_1587984203_6764.png"
              width="133"
              height="60"
              className="d-inline-block align-top mb-5 "
            />{' '}
           
          </Navbar.Brand>
        </Container>
      </Navbar>
    
      
    
 

   
  )
}

export default Header