import React from 'react'
import { Container, Row, Col, Image, Tabs, Tab } from 'react-bootstrap';


const UserProfile = () => {
  return (
    

    <Container className="">
    <Row className='mt-1 mx-2 border border-blue position-relative '>
    <div className='d-flex justify-content-center position-absolute top-0 start-0 translate-middle '>
      <p className='ms-3 mt-3 bg-secondary text-white px-2 py-1'> 队长 </p>
    </div>
      <Col md={4} >
      <Image
              alt=""
              src="https://64.media.tumblr.com/1f1128ea37c1933d64b92cf8109589b7/87fcfc4668007220-1d/s400x600/7cd05bc0da180e0219a279afd44b2771e75ea4d8.png"
              width="64"
              height="64"
              className="r-flex justify-content-center  ms-3"
              roundedCircle
           />{' '}
       
      </Col>
      <Col md={8} className='my-auto'>
        <p >"username"</p>
        <p>"个人总分: 123,545"</p>
      </Col>
    </Row>
    <Row className='mt-1 mx-2 border border-blue' style={{display : "flex",
"align-items": "center ",
"justify-items":" center"}}> 
      <Col md={4} >
      <Image
              alt=""
              src="https://64.media.tumblr.com/1f1128ea37c1933d64b92cf8109589b7/87fcfc4668007220-1d/s400x600/7cd05bc0da180e0219a279afd44b2771e75ea4d8.png"
              width="64"
              height="64"
              className="r-flex justify-content-center ms-3 "
              roundedCircle
           />{' '}
       
      </Col>
      <Col md={8} >
        <p >"username"</p>
        <p>"个人总分: 123,545"</p>
      </Col>
    </Row>
    <Row className='mt-1 mx-2 border border-blue'>
      <Col md={4}>
      <Image
              alt=""
              src="https://64.media.tumblr.com/1f1128ea37c1933d64b92cf8109589b7/87fcfc4668007220-1d/s400x600/7cd05bc0da180e0219a279afd44b2771e75ea4d8.png"
              width="64"
              height="64"
              className="r-flex justify-content-center  ms-3" 
              roundedCircle
           />{' '}
       
      </Col>
      <Col md={8} className='my-auto'>
        <p >"username"</p>
        <p>"个人总分: 123,545"</p>
      </Col>
    </Row>
    <Row className='mt-1 mx-2 border border-blue'>
      <Col md={4}>
      <Image
              alt=""
              src="https://64.media.tumblr.com/1f1128ea37c1933d64b92cf8109589b7/87fcfc4668007220-1d/s400x600/7cd05bc0da180e0219a279afd44b2771e75ea4d8.png"
              width="64"
              height="64"
              className="r-flex justify-content-center  ms-3"
              roundedCircle
           />{' '}
       
      </Col>
      <Col md={8} className='my-auto'>
        <p >"username"</p>
        <p>"个人总分: 123,545"</p>
      </Col>
    </Row>
    <Row className='mt-1 mx-2 border border-blue'>
      <Col md={4}>
      <Image
              alt=""
              src="https://64.media.tumblr.com/1f1128ea37c1933d64b92cf8109589b7/87fcfc4668007220-1d/s400x600/7cd05bc0da180e0219a279afd44b2771e75ea4d8.png"
              width="64"
              height="64"
              className="r-flex justify-content-center ms-3 "
              roundedCircle
           />{' '}
       
      </Col>
      <Col md={8} className='my-auto'>
        <p >"username"</p>
        <p>"个人总分: 123,545"</p>
      </Col>
    </Row>

    
  </Container>

  )
}

export default UserProfile