import React from 'react'

const Investigation = () => {
  return (
    <div >
        111
    </div>
  )
}

export default Investigation